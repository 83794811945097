<template>
  <div id="Bought">
    <h1>已购买</h1>
  </div>
</template>

<script>
export default {
  name: 'Bought',
};
</script>

<style></style>
